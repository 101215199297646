.startScreen {
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.deck {
  width: 180px;
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
  border: 1px solid black;
  box-shadow: 0px 4px 8px rgba(149, 140, 133, 0.49);
  transition: transform 0.2s ease-out;
}

.deck:hover {
  transform: translateY(-10px);
}

.chooseDeckText {
  height: 78px;
  /* width: auto; */
  margin-bottom: 3px;
  margin-top: -60px;
}

.decks {
  display: flex;
}
