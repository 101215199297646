.deck {
  height: 320px;
  width: 1000px;
  position: relative;
  transform: scale(0.9);
  margin-top: -45px;
  margin-left: 0;
  z-index: 4;
  transition: transform 0.2s ease-in-out;
}

:global(.isOnTable-true) .deck {
  transform: scale(0.7) translateY(calc(80vh - 120px));
}
