.card {
  width: 175px;
  height: 320px;
  /* background-color: #cedfd6; */
  border: 8px solid #f5f2ef;
  box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.1);
  border-radius: 23px;
  flex-shrink: 0;
  margin-left: -176px;
  transition: top 0.4s ease-in-out, left 0.4s ease-in-out;
  cursor: pointer;
  z-index: 1;
  position: absolute;
}

.card.notSelected:hover {
  animation: FloatCard 0.1s ease-in;
}

.card.notSelected {
  animation: HideCard 0.5s ease-out;
}

.card:hover,
.card.selected {
  margin-top: -40px;
  box-shadow: 0px 4px 8px rgba(68, 66, 63, 0.49);
}

.card.selected {
  transition: transform 0.2s ease-out;
  box-shadow: 0 0 0 1px black;
  transform: rotate(-2deg) translate(3px, 10px);
}

.card.inPile {
  animation: none !important;
  margin-top: 0 !important;
}

.card.topCard.inPile:hover {
  box-shadow: 0 0 0 2px black;
}

.cardContainer {
  position: relative;
}

.displayNumber {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  width: 50px;
  /* bottom: -40px; */
}

@keyframes FloatCard {
  0% {
    margin-top: 0;
  }
  100% {
    margin-top: -40px;
  }
}

@keyframes HideCard {
  0% {
    margin-top: -40px;
  }
  100% {
    margin-top: 0;
  }
}
