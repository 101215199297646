.App {
  display: flex;
  position: relative;
  height: 100vh;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: linear-gradient(
    166.18deg,
    rgba(217, 209, 248, 0.81) 9.87%,
    rgba(241, 220, 230, 0.81) 34.7%,
    rgba(240, 208, 179, 0.81) 89.32%
  );
}

.background {
  background: url(img/noise-contrast2.gif);
  opacity: 0.2;
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  background-size: 100px;
}

body {
  position: relative;
}

#session-in-progress {
  position: absolute;
  top: 10px;
  left: 23px;
  height: 32px;
}

#leave-session {
  position: absolute;
  top: 6px;
  right: 2px;
  height: 32px;
}

img.button:hover {
  opacity: 70%;
}

img.button {
  transition: opacity 0.2s ease-in-out;
}

img.button.hidden {
  opacity: 0;
  display: none;
}

.line {
  height: 1px;
  width: 100vw;
  position: absolute;
  background-color: black;
}

#top-line {
  top: 0;
  left: 0;
}

#bottom-line {
  top: 44px;
  left: 0;
}

.face {
  width: 110px;
  position: absolute;
}

#face1 {
  left: 44px;
  top: 70px;
}

#face2 {
  left: 44px;
  bottom: 110px;
}

#face3 {
  right: 44px;
  top: 70px;
}

#face4 {
  right: 44px;
  bottom: 110px;
}

#bottom-bar {
  width: 100vw;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: black;

  height: 100px;
}

#music-text {
  position: absolute;
  left: 44px;
  bottom: 38px;
  height: 21px;
  z-index: 1;
}

#table-text {
  position: absolute;
  z-index: 1;
  height: 28px;
  bottom: 36px;
  left: 0;
  right: 0;
  margin: auto;
}

.isOnTable-true #table-text {
  opacity: 0;
}

#fullscreen-text {
  position: absolute;
  right: 44px;
  bottom: 40px;
  height: 26px;
  z-index: 1;
}

#instruction-text {
  position: absolute;
  right: 0;
  left: 0;
  margin: auto;
  top: 120px;
  height: 18px;
  z-index: 1;
}

img.button {
  cursor: pointer;
  margin-top: 50px;
  height: 50px;
  position: absolute;
  bottom: 140px;
  left: 0;
  right: 0;
  margin: auto;
}

.isOnTable-true #instruction-text {
  opacity: 0;
}

.isOnTable-true img.button {
  opacity: 0;
}

img {
  animation: FadeIn 0.2s ease-in;
}

@keyframes FadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
